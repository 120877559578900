import { log } from '@/utils/logger';

/**
 * @todo refactor for next 14
 */
export const PERSISTENT_STATE_KEYS = {
  jwt: 'jwt',
  user: 'prusuki_current_user',
  searchHistory: 'prusuki_search_history',
  previewMode: 'preview_mode',
  previewModeCookie: 'preview-mode',
};

const getCurrentCookies = () => {
  if (!process.browser) return null;

  const currentCookies = document.cookie;
  return currentCookies;
};

interface getCookieProps {
  key: string;
  cookies?: string;
}

const getCookie = ({ key, cookies }: getCookieProps) => {
  const serialized_coolies = cookies || getCurrentCookies();

  if (typeof serialized_coolies !== 'string') return null;

  const cookie = serialized_coolies.split(';').reduce((acc, item) => {
    const [key, value] = item.split('=');
    acc[key.trim()] = value;
    return acc;
  }, {});

  let cookieValue = cookie[key];

  try {
    cookieValue = JSON.parse(cookieValue);
  } catch {
    log.error('Cannot parse string');
    // throw new Error('Cannot parse string');
  }

  return cookieValue;
};

function sanitizeForJson(value) {
  if (typeof value !== 'string') {
    throw new TypeError('Input must be a string');
  }

  return (
    value
      // .replace(/[\u0000-\u001F\u007F]/g, '') // Remove control characters
      .replace(/\\/g, '') // Remove backslashes
      .replace(/;/g, '') // Remove backslashes
      .replace(/"/g, '') // Remove double quotes
    // .replace(/'/g, '')
  );
}

const setCookie = ({ key, value }) => {
  // const currentCookies = getCurrentCookies();
  let cookieValue = sanitizeForJson(value);

  if (typeof cookieValue === 'object')
    cookieValue = JSON.stringify(cookieValue);

  const expiryDate = new Date();
  expiryDate.setTime(expiryDate.getTime() + 7 * 24 * 60 * 60 * 1000); // 7 days from now
  const newCookie =
    `${key}=${cookieValue}; expires=` + expiryDate.toUTCString();

  // const mergedCoolies = newCookie + ';' + currentCookies;

  document.cookie = newCookie;
};

const deleteCookie = ({ key }) => {
  // const currentCookies = getCurrentCookies();

  if (document.cookie.split(';').some(item => item.includes(`${key}=`))) {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  }
};

const setSession = ({ key, value }) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

const getSession = ({ key }) => {
  const sessionValue = sessionStorage.getItem(key);

  if (!sessionValue) {
    log.error('No session value found');
    return null;
  }

  try {
    return JSON.parse(sessionValue);
  } catch {
    return sessionValue;
  }
};

const deleteSession = ({ key }) => {
  sessionStorage.removeItem(key);
};

interface Exposed {
  cookie: {
    get: ({ key }: getCookieProps) => unknown;
    set: ({ key, value }: { key: string; value: unknown }) => void;
    delete: ({ key }: { key: string }) => void;
  };
  session?: {
    get: ({ key }: { key: string }) => unknown;
    set: ({ key, value }: { key: string; value: unknown }) => void;
    delete: ({ key }: { key: string }) => void;
  };
}

const exposed: Exposed = {
  cookie: {
    get: getCookie,
    set: setCookie,
    delete: deleteCookie,
  },
};

if (typeof window !== 'undefined') {
  exposed.session = {
    get: getSession,
    set: setSession,
    delete: deleteSession,
  };
}

export default exposed;
