const JWT_COOKIE_NAME = 'jwt';

export const getJwtCookie = () => {
  const cookies = document.cookie;

  const jwtCookie = cookies.replace(
    new RegExp(
      '(?:(?:^|.*;\\s*)' + JWT_COOKIE_NAME + '\\s*=\\s*([^;]*).*$)|^.*$'
    ),
    '$1'
  );

  return jwtCookie;
};
