/**
 * @description rest api endpoints
 */
export enum Endpoints {
  info = 'info',
  article = 'article',
  articles = 'articles',
  guide = 'guide',
  guides = 'guides',
  steps = 'steps',
  category = 'category',
  categories = 'categories',
  feedback = 'feedback',
  comments = 'comments',
  commentAction = 'commentAction',
  downloads = 'downloads',
  materials = 'materials',
  user = 'user',
  qrCode = 'qrCode',
  logout = 'logout',
  related = 'related',
  tooltips = 'tooltips',
  productPageContentBlocks = 'productPageContentBlocks',
  pdfs = 'pdfs',
  search = 'search',
  googleVertexSearch = 'googleVertexSearch',
  forumListTopics = 'forumListTopics',
  forumCategoryById = 'forumCategoryById',
  vertex = 'vertex',
  recaptcha = 'recaptcha',
  profiles = 'profiles',
}

/**
 * @description server URLs
 * @todo process.browser deprecated
 */
const isBrowser = process.browser;

const HelpServers = new Map([
  [true, process.env.NEXT_PUBLIC_ORIGIN],
  [false, process.env.WP_API_SERVER || process.env.NGINX_CONTAINER_URL],
]);

/**
 * @todo remove harcoded url for forum
 */
const servers = {
  help: HelpServers,
  forum: 'https://cache.prusa3d.com/forum/wp-json/p3d/v1/forum/', // process.env.NEXT_PUBLIC_FORUM_API_BASE_URL,
  googleVertexSearch:
    'https://discoveryengine.googleapis.com/v1alpha/projects/885017487941/locations/global/collections/default_collection/engines/help-search-web_1738053397528/servingConfigs/default_search:search',
  slicer: 'https://preset-repo-api.prusa3d.com/', // stage: preset-repo-api-stage
};

const helpServerUrl = servers.help.get(isBrowser);

/**
 * @description base paths for rest api endpoints
 */
const basepath = {
  v1: `${helpServerUrl}/api/v1`,
  v2: `${helpServerUrl}/api/wp/v2`,
  help: `${helpServerUrl}/api/prusuki`,
  edge: `${helpServerUrl}/edge`,
  search: `${helpServerUrl}/search-index`,
  forum: servers.forum,
  googleVertexSearch: servers.googleVertexSearch,
  slicer: `${servers.slicer}v1/repos`,
};

/**
 * @description rest api endpoints
 */
export const endpoints = {
  // prusuki WP BE
  [Endpoints.info]: `${basepath.v1}/${Endpoints.info}`,
  [Endpoints.article]: `${basepath.v1}/helps`, // alias
  [Endpoints.articles]: `${basepath.v1}/helps`,
  [Endpoints.guide]: `${basepath.v1}/${Endpoints.guides}`, // alias
  [Endpoints.guides]: `${basepath.v1}/${Endpoints.guides}`,
  [Endpoints.steps]: `${basepath.v1}/${Endpoints.steps}`,
  [Endpoints.category]: `${basepath.v1}/categories`, // alias
  [Endpoints.categories]: `${basepath.v1}/${Endpoints.categories}`,
  [Endpoints.feedback]: `${basepath.v1}/${Endpoints.feedback}`,
  [Endpoints.comments]: `${basepath.v1}/${Endpoints.comments}`,
  [Endpoints.commentAction]: `${basepath.v2}/${Endpoints.comments}`,
  [Endpoints.downloads]: `${basepath.v1}/prusa3d_downloads`,
  [Endpoints.materials]: `${basepath.v1}/${Endpoints.materials}`,
  [Endpoints.user]: `${basepath.help}/${Endpoints.user}`,
  [Endpoints.qrCode]: `${basepath.v1}/qr_codes`,
  [Endpoints.logout]: `${basepath.help}/${Endpoints.logout}`,
  [Endpoints.related]: `${basepath.v1}/${Endpoints.related}`,
  [Endpoints.tooltips]: `${basepath.v1}/${Endpoints.tooltips}`,
  [Endpoints.productPageContentBlocks]: `${basepath.v1}/view`,
  [Endpoints.pdfs]: `${basepath.v1}/${Endpoints.pdfs}`,
  [Endpoints.search]: `${basepath.v1}/${Endpoints.search}`,
  [Endpoints.googleVertexSearch]: `${basepath.googleVertexSearch}`,
  [Endpoints.forumListTopics]: `${basepath.forum}/list-topics`,
  [Endpoints.forumCategoryById]: `${basepath.forum}id`,
  [Endpoints.vertex]: `${basepath.edge}/${Endpoints.vertex}`,
  [Endpoints.recaptcha]: `${basepath.edge}/${Endpoints.recaptcha}`,
  [Endpoints.profiles]: `${basepath.slicer}`,
} as const;

export const freshDataEndpoints = [
  'comments',
  'commentAction',
  'feedback',
  'user',
  'logout',
  'forumCategoryById',
  'forumListTopics',
  'qrCode',
  'search',
  'pdfs',
  'googleVertexSearch',
  // 'downloads',
  'vertex',
  'recaptcha',
  'profiles',
];

// /**
//  * @description data types for rest api endpoints
//  */
// export type Endpoints = keyof typeof endpoints;

export type EndpointTypeMap = {
  [K in Endpoints]: K extends 'articles' | 'article'
    ? ArticleData[]
    : K extends 'guides' | 'guide'
    ? GuideData[]
    : K extends 'steps'
    ? StepData[]
    : K extends 'info'
    ? InfoData
    : K extends 'categories' | 'category'
    ? CategoryData[]
    : K extends 'forumCategoryById' | 'forumListTopics'
    ? ForumTopics[]
    : K extends 'materials'
    ? MaterialData[]
    : K extends 'qrCode'
    ? QRCodeData
    : K extends 'search'
    ? SearchItem[]
    : K extends 'related'
    ? ArticleData[]
    : K extends 'productPageContentBlocks'
    ? ViewData[]
    : K extends 'pdfs'
    ? PdfData[]
    : K extends 'googleVertexSearch' | 'vertex'
    ? VertexSearchData[]
    : K extends 'downloads'
    ? Prusa3dDownload[]
    : never;
};
