import { log } from '@/utils/logger';

export const validateUrl = url => {
  if (!url) {
    log.throw({
      event: 'validateUrl',
      message: 'URL is required',
    });
  }

  // is URL a valid url?
  try {
    new URL(url);
  } catch (error) {
    /**
     * @todo logger - different in dev
     */
    throw new Error(`URL is invalid: ${url}`);
  }

  return url;
};
