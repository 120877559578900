import { cachedFetch } from './cachedFetch';
import { customHttpAgentForDev } from './customHttpAgent';
import { validateUrl } from '../../urlManipulations/validateUrl';
import { isDebugMode } from '@/constants';
import { log } from '@/utils/logger';
// import { authenticateUser } from '../../authenticateUser';
// import { log } from '@/utils/logger';

const isEmpty = obj => {
  if (!obj) return true;

  if (Array.isArray(obj)) return obj.length === 0;

  return Object.keys(obj).length === 0;
};

export const fetchRestApiEndpoint = async (url, options) => {
  const validatedUrl = validateUrl(url);

  const defaultHeaders = {};

  const headers = {
    ...defaultHeaders,
    ...(isEmpty(options.headers) ? {} : options.headers || {}),
  };

  const requestOptions = {
    ...customHttpAgentForDev(),
    ...options,
    headers,
  };

  const request = new Request(validatedUrl, requestOptions);

  try {
    const response: Response = await cachedFetch(request);

    // if 409
    if (
      response.status.toString().startsWith('4') &&
      response.headers.get('Content-Type')?.includes('application/json')
    ) {
      const json = await response.json();
      if (process.env.NODE_ENV === 'development') {
        throw new Error(json.message);
      }
    }

    if (response.ok) {
      // log in debug mode
      if (!response.headers.get('x-nextjs-cache')) {
        log.debug({
          event: 'fetching BE',
          message: 'response ok',
          stringyfy: { url, options, status: response.status },
        });
      }

      return response;
    }
    // else {
    //   log.throw({
    //     event: 'responseNotOk',
    //     message: 'Response not ok',
    //     stringify: { url, options, status: response.status },
    //   });
    // }
  } catch (error) {
    console.error(error);
    if (isDebugMode) {
      throw error;
    }
    // log.throw({
    //   event: 'fetchFailed',
    //   message: error?.message || 'Error',
    //   stringify: {
    //     url,
    //     headersObject: requestOptions.headers,
    //     headers: requestOptions.headers,
    //     options,
    //     errorName: error.name,
    //     errorMessage: error.message,
    //     errorStack: error.stack,
    //   },
    // });
  }
};
