import { fetchRestApiEndpoint } from './utils/fetchRestApiEndpoint';
import { Methods } from '../../types.d';
import { log } from '@/utils/logger';

export interface fetchPostProps {
  url: string;
  headers?: Record<string, string>[] | object;
  body: Record<string, unknown>;
}

export const fetchPost = async ({
  url,
  headers = {},
  body,
}: fetchPostProps) => {
  const options = {
    method: Methods.POST,
    redirect: 'follow', // for /qr_codes endpoint
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...(headers || {}),
    },
    body: JSON.stringify(body),
  };
  let response;

  try {
    response = await fetchRestApiEndpoint(url, options);
  } catch (error) {
    log.throw({
      event: 'fetching BE',
      message: error.message || 'Error fetching BE',
      stringyfy: { url, options, error },
    });
  }

  if (!response) {
    log.throw({
      event: 'fetching BE',
      message: 'No response',
      stringyfy: { url, options },
    });
    return;
  }

  if (response.ok === false) {
    throw new Error(response.statusText);
  }

  // log in debug mode
  // log.debug({
  //   event: 'fetching BE',
  //   message: 'response ok',
  //   stringyfy: { url, options, status: response.status },
  // });

  try {
    const json: RestPayload = await response.json();
    return json;
  } catch (error) {
    log.throw({
      event: 'fetching BE',
      message: 'Response is not JSON',
      stringyfy: { url, options, text: await response?.text?.() },
    });
  }
};
