/**
 * @todo @tanstack/react-query
 */
'use client';

import { endpoints } from './endpoints';
import { fetchJson } from './fetchers/fetchJson';
import { fetchPost } from './fetchers/fetchPost';
import { parseUrl } from './urlManipulations/parseUrl';
import { AUTHORISATION_HEADER_NAME } from '@/constants';
import { getAuthorizationHeader } from '@/lib/authentification/getAuthorizationHeader';
import persistentState, { PERSISTENT_STATE_KEYS } from '@/lib/persistentState';

/**
 * @todo refactor - unify with the other api
 */
const findEndpoint = enpointKey => {
  if (endpoints[enpointKey]) {
    return endpoints[enpointKey];
  }

  throw new Error('Endpoint not defined');
};

type Signal = AbortSignal | null;

interface PostMethodProps {
  body: Record<string, string | number | boolean | null>;
  headers?: HeadersInit;
  id?: number | undefined | null;
}

class ClientRequest {
  url: string;
  signal: Signal;

  constructor(enpointKey) {
    this.url = findEndpoint(enpointKey);
    this.signal = null;
  }

  with(signal) {
    this.signal = signal;
    return this;
  }

  async get(params) {
    const url = parseUrl({
      baseUrl: this.url,
      params,
    });

    const authHeader = getAuthorizationHeader();
    const headers = { ...authHeader };

    return fetchJson({ url, signal: this.signal, headers });
  }

  async post({ body, headers, id }: PostMethodProps) {
    let url = parseUrl({
      baseUrl: this.url,
    });

    if (id) {
      url = `${url}/${id}`;
    }

    const token = persistentState.cookie.get({
      key: PERSISTENT_STATE_KEYS.jwt,
    });

    /**
     * @todo refactor - which auth header?
     */
    const headersWithAuth = {
      ...headers,
      // Authorization: `Bearer ${token}`,
      [AUTHORISATION_HEADER_NAME]: `Bearer ${token}`,
    };

    return fetchPost({ url, headers: headersWithAuth, body });
  }
}

export default ClientRequest;
