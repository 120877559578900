export const SHOULD_USE_CDN =
  process.env.NEXT_PUBLIC_ORIGIN === 'https://help.prusa3d.com';

export const CDN_URL = 'https://cdn.help.prusa3d.com';

export const POST_TYPES = ['helps', 'guides', 'category', 'products'] as const;

export const AUTHORISATION_HEADER_NAME = 'Authorization';

export const MEDICAL_ROLES = ['medic', 'medical_support'];

export const isDebugMode = process.env.NEXT_PUBLIC_DEBUG_MODE === 'true';
