import https from 'https';
// import fs from 'fs';
// import path from 'path';

const isBrowser = process.browser;

const getAgentObject = () => {
  if (isBrowser) {
    return null;
  }

  const agent = new https.Agent({
    // rejectUnauthorized: false,
    // custom pem
    // ca: fs.readFileSync('/home/node/app/certificates/pkb.local.pem')
  });

  return agent;
};

/**
 * @description skip SSL verification in development (self-signed certificate pkb.local)
 */
export const customHttpAgentForDev = () => {
  if (process.env.NODE_ENV === 'development')
    return { agent: getAgentObject() };

  return {};
};
