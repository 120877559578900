import { fetchRestApiEndpoint } from './utils/fetchRestApiEndpoint';
import { log } from '@/utils/logger';

type Signal = AbortSignal | null;

interface FetchJsonProps {
  url: string;
  signal?: Signal;
  headers?: Record<string, string> | null;
  options?: RequestInit;
}

const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const fetchJson = async ({
  url,
  signal = null,
  headers: additionalHeaders = {},
  options: additionalOptions = {},
}: FetchJsonProps) => {
  const headers = defaultHeaders;
  const mergedHeaders = { ...headers, ...(additionalHeaders || []) };

  const options = {
    method: 'GET',
    headers: mergedHeaders,
    signal,
    ...additionalOptions,
  };

  const response = await fetchRestApiEndpoint(url, options);

  if (!response) {
    log.throw({
      event: 'fetching BE',
      message: 'No response',
      stringyfy: { url, options },
    });
    return;
  }

  if (response.ok === false) {
    throw new Error(response.statusText);
  }

  try {
    const json: RestPayload = await response.json();
    return json;
  } catch (error) {
    throw new Error('Response is not JSON');
  }
};
