export enum Methods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export interface FormattedRestPayload<T> {
  data: T;
  // if T extends array, then it gets type U using infer,
  // which represents one item of the array type T.
  // If the T is not an array, type is null
  firstItem: T extends (infer U)[] ? U : T | null;
  info: RestInfoPayload; // Record<>
}

export interface RequestObjectInterface {
  baseUrl: string;
  params?: Record<string, string | number | boolean>;
}
