import { getJwtCookie } from '@/ui/components/AuthProvider/getJwtCookie';

export const getAuthorizationHeader = () => {
  const jwt = getJwtCookie();

  if (!jwt) {
    return {};
  }

  const bearer = `Bearer ${jwt || ''}`;

  const header = { Authorization: bearer };

  return header;
};
