// import { getMedicalParam } from './getMedicalParamServerSide';
import { parseParams } from './parseParams';
import { validateUrl } from './validateUrl';
import type { RequestObjectInterface } from '../../types';

type ParseUrlProps = RequestObjectInterface | string;

export const parseUrl = (props: ParseUrlProps): string => {
  if (typeof props === 'string') {
    return validateUrl(props);
  }

  const { baseUrl, params } = props;
  // const medicalParam = getMedicalParam();

  const validatedUrl = validateUrl(baseUrl);
  const url = new URL(validatedUrl);

  if (params) {
    const urlQuery = parseParams(params);
    url.search = urlQuery;
  }

  return url.toString();
};
