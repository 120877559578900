const paramsToTranslate = {
  language: 'lng',
};

/**
 * Translate params to the format that is used by WP API
 */
const translateParams = (params: object) => {
  const translatedParams = Object.entries(params).reduce(
    (acc, [key, value]) => {
      const translatedKey = paramsToTranslate[key] || key;
      acc[translatedKey] = value;
      return acc;
    },
    {}
  );

  return translatedParams;
};

const makeSearchParamsString = (params: object) => {
  const urlQuery = new URLSearchParams();
  const paramsArray = Object.entries(params);

  const filteredParams = paramsArray.filter(([, value]) => value);

  filteredParams.forEach(([key, value]) => urlQuery.append(key, value));

  // sort params alphabetically
  urlQuery.sort();

  return urlQuery.toString();
};

export const parseParams = (params: object) => {
  if (!params) {
    throw new Error('Params are required');
  }

  if (typeof params !== 'object') {
    throw new Error('Params must be an object');
  }

  const translatedParams = translateParams(params);
  const searchParamsString = makeSearchParamsString(translatedParams);

  return searchParamsString;
};
